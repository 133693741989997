;(function () {
  // Function to get cookie value by name
  function getCookie(name) {
    let value = `; ${document.cookie}`
    let parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  // Function to set a cookie
  function setCookie(name, value, days) {
    let expires = ''
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  // Set first landing page cookie if not already set
  if (!getCookie('first_landing_page')) {
    setCookie('first_landing_page', window.location.href, 365)
  }

  // Set session start time cookie
  setCookie('session_start_time', new Date().toISOString(), 1)

  // Set last interaction time cookie
  document.addEventListener('click', function () {
    setCookie('last_interaction_time', new Date().toISOString(), 1)
  })

  // Set visit count cookie
  let visitCount = getCookie('visit_count')
  visitCount = visitCount ? parseInt(visitCount) + 1 : 1
  setCookie('visit_count', visitCount, 365)

  // Set campaign source cookie if available in URL
  let urlParams = new URLSearchParams(window.location.search)
  
  const possibleParameters = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    '__ga',
    '_gid',
    '__gads',
    '__gac',
    '_fbp',
    'fr',
    '_uetsid',
    '_uetvid',
    '_hjSessionUser_',
    '_hjSession_',
    '_hjid',
    '_mkto_trk',
    '_gid',
    '_gat',
    '__utmz',
    '__utmc',
    '__utma',
    '_gaexp',
    'cto_lwid',
    'cto_idcpy',
    'ajs_anonymous_id',
    'ajs_user_id',
    '_gcl_au',
    'li_fat_id',
    '_pxhd',
    's_cc',
    's_fid',
    's_sq',
    's_vi',
    'trkCode',
    'trkInfo',
    'id',
    'IDE',
    'mcid',
  ]
  // if urlparams has one of possibleparameters, add as cookie
  possibleParameters.forEach((param) => {
    if (urlParams.has(param)) {
      setCookie(param, urlParams.get(param), 365)
    }
  })

  // Set referral URL cookie if not already set
  if (!getCookie('referral_url')) {
    setCookie('referral_url', document.referrer, 365)
  }

  // Set user preferences cookie (example)
  if (!getCookie('user_preferences')) {
    let preferences = {
      language: navigator.language || 'en',
      theme: 'light',
      interests: ['technology', 'marketing'],
    }
    setCookie('user_preferences', JSON.stringify(preferences), 365)
  }
})()
