document.addEventListener('DOMContentLoaded', function () {
  function handleLabelClick(event) {
    const label = event.target
    const choiceContainer = label.closest('.gchoice')
    const otherInput = choiceContainer.querySelector('.gchoice_other_control')
    if (otherInput) {
      if (!otherInput.hasAttribute('data-user-input')) {
        otherInput.value = ''
      }
    }
  }

  function handleInputClick(event) {
    const otherInput = event.target
    const choiceContainer = otherInput.closest('.gchoice')
    const radioInput = choiceContainer.querySelector('input[type="radio"]')
    radioInput.checked = true
  }

  function handleInputChange(event) {
    const otherInput = event.target
    otherInput.setAttribute('data-user-input', 'true')
  }

  document.querySelectorAll('.gchoice .gform-field-label').forEach((label) => {
    label.addEventListener('click', handleLabelClick)
  })

  document.addEventListener('click', function (event) {
    const currentTarget = event.target
    const isOtherChoiseInput = currentTarget.classList.contains('gfield-choice-input')
    if (!isOtherChoiseInput) {
      return
    }
    const nameOfOtherChoiseInput = currentTarget.getAttribute('name')
    const otherInput = document.querySelector(`input[name="${nameOfOtherChoiseInput}_other"]`)
    if (!otherInput) {
      return
    }
    window.setTimeout(() => {
      otherInput.focus()
    }, 10)
  })


  document
    .querySelectorAll('.gchoice .gchoice_other_control')
    .forEach((input) => {
      input.addEventListener('click', handleInputClick)
      input.addEventListener('input', handleInputChange)
    })

  const selectElements = document.querySelectorAll('select')

  selectElements.forEach((element) => {
    const checkSelect = () => {
      element.classList.toggle('filled', element.value !== '')
    }

    element.addEventListener('change', checkSelect)
    checkSelect()
  })

  const inputFields = document.querySelectorAll(
    'input[type="text"], input[type="email"], input[type="tel"], input[type="url"], input[type="password"], input[type="search"], input[type="number"]'
  )
  inputFields.forEach((element) => {
    const checkInputfield = () => {
      element.classList.toggle('filled', element.value.trim() !== '')
    }

    element.addEventListener('input', checkInputfield)
    checkInputfield()
  })
})
