import crumbs from 'crumbsjs';
import { canUseLocalStorage, cookieConsentIsSet, isDesktop } from './helpers';

function useLocalStorage() {
  const cookieNoticerCk = crumbs.get('acceptedCookies');
  const cookieNoticerLs = crumbs.ls.get('acceptedCookies');
  if (canUseLocalStorage() === true) {
    if (cookieNoticerCk === 'accepted' || cookieNoticerLs === 'accepted') {
      return true;
    } else {
      return false;
    } 
  } else {
    return false;
  }
}

class Prikr_Popup {
  constructor(options) {
    this.options = {
      element_id: '',
      background_element_id: '',
      top_only: false,
      click_outside_to_close: true,
      delay: 3000,
      path_regex: '',
      will_show: null,
      delay_expired: null,
      show_on_exit: true,
      ...options,
    };

    
  }

  reset_flag() {
    if (useLocalStorage()) {
      localStorage.setItem('prikr.exitpopup.can_show', '1');
    }
  }

  get(id, callback) {
    var domEl = document.getElementById(id);
    if (domEl != null) {
      callback(domEl);
    }
  }

  show() {
    if (!useLocalStorage()) return;
    if (useLocalStorage()) {
      if (!this.options.delay_expired || localStorage.getItem('prikr.exitpopup.can_show', '1') == '0') {
        return;
      }
    } else {
      if (!this.options.delay_expired) {
        return;
      }
    }

    if (this.options.will_show != null) {
      const result = this.options.will_show();
      if (result === false) {
        return;
      }
    }

    if (useLocalStorage()) {
      localStorage.setItem('prikr.exitpopup.can_show', '0');
    }

    this.get(this.options.background_element_id, function (e) {
      e.style.opacity = '1';
      e.style.pointerEvents = 'auto';
    });

    this.get(this.options.element_id, function (e) {
      e.style.transform = 'translateY(0)';
      e.style.opacity = '1';
      e.style.pointerEvents = 'auto';
    });

    let weplychat = document.querySelector('iframe[title="Weply chat"]');
    weplychat.style.pointerEvents = 'none';
    weplychat.style.opacity = '0';

    this.closeButton = document.getElementById('popup_close_button');
    if (this.closeButton) {
      // Add event listener to handle click event on the close button
      this.closeButton.addEventListener('click', this.hide.bind(this));
    }

    this.ctaButton = document.getElementById('popup_cta_button');
    if (this.ctaButton) {
      // Add event listener to handle click event on the close button
      this.ctaButton.addEventListener('click', this.showCta.bind(this));
    }
  }

  hide() {
    // Keep flag coming up without cookies e.d.
    // this.reset_flag()

    this.get(this.options.background_element_id, function (e) {
      e.style.opacity = '0';
      e.style.pointerEvents = 'none';
    });

    this.get(this.options.element_id, function (e) {
      e.style.transform = 'translateY(130%)';
      e.style.opacity = '0';
      e.style.pointerEvents = 'none';
    });

    let weplychat = document.querySelector('iframe[title="Weply chat"]');
    weplychat.style.pointerEvents = 'auto';
    weplychat.style.opacity = '1';
  }

  showCta() {
    this.ctaButton.style.opacity = '0';
    this.ctaButton.style.pointerEvents = 'none';
    this.extraContent = document.getElementById('popup_extracontent');
    if (!isDesktop()) {
      this.get('popup_content__wrapper', function (e) {
        e.style.overflow = 'scroll';
        e.style.display = 'block';
      });
    }
    if (this.extraContent) {
      this.extraContent.style.opacity = '1';
      this.extraContent.style.pointerEvents = 'auto';
      this.extraContent.style.maxHeight = '999px';
    }
  }

  initialize() {
    this.reset_flag()
    setTimeout(() => {
      const pre = this.options.path_regex;
      if (pre != null && pre != '') {
        const loc = window.location.pathname;
        const re = new RegExp(pre);
        if (!re.test(loc)) {
          return;
        }
      }

      const prikr_mouse_out = (event) => {
        if (
          // if exit through top bar
          event.clientY <= 1 ||
          // or exit somewhere else and we are allowing exit anywhere
          !this.options.top_only
          // and not totally disabled
          && this.options.show_on_exit !== false
        ) {
          this.show();
        }
      };

      const prikr_mouse_click = (event) => {
        this.get(this.options.element_id, (e) => {
          if (e.style.display == 'block') {
            const rect = e.getBoundingClientRect();
            if (
              event.clientX >= rect.left &&
              event.clientX <= rect.right &&
              event.clientY >= rect.top &&
              event.clientY <= rect.bottom
            ) {
              // in popup, ignore
            } else if (this.options.click_outside_to_close) {
              this.hide();
            }
          }
        });
      };

      // document.addEventListener("mouseout", prikr_mouse_out);
      document.documentElement.addEventListener("mouseleave", prikr_mouse_out);
      document.documentElement.addEventListener("click", prikr_mouse_click);

      if (this.options.show_after_seconds !== false && this.options.delay > 0) {

        setTimeout(() => {
          this.options.delay_expired = true;
        }, this.options.delay)

        setTimeout(() => {
          this.show();
        }, this.options.show_after_seconds * 1000);

      } else if (this.options.delay > 0) {
        setTimeout(() => {
          this.options.delay_expired = true;
        }, this.options.delay)
      }
    }, 100);
  }
}

// Export the class for global usage
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = Prikr_Popup;
} else {
  if (typeof window === 'object') {
    window.Prikr_Popup = Prikr_Popup;
  }
}