import Accordion from 'accordion-js';
import {
  isDesktop
} from './helpers';

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {

    let collapseContents = document.querySelectorAll('.block__faq__accordion');
    [...collapseContents].forEach((collapse) => {
      let Acc = new Accordion('#faq_' + collapse.dataset.collapseId, {
        duration: 300,
        ariaEnabled: true,
        collapse: true,
        showMultiple: false,
        openOnInit: [],
        elementClass: 'block__faq__accordion__item',
        triggerClass: 'block__faq__accordion__button',
        panelClass: 'block__faq__accordion__panel',
        activeClass: 'collapsed'
      });
    })

    if (!isDesktop()) {
      if (document.querySelector('#footer-2')) {
        let Acc2 = new Accordion('#footer-2', {
          duration: 300,
          ariaEnabled: true,
          collapse: true,
          showMultiple: false,
          openOnInit: [],
          activeClass: 'collapsed'
        });
      }
      if (document.querySelector('#footer-3')) {

        let Acc3 = new Accordion('#footer-3', {
          duration: 300,
          ariaEnabled: true,
          collapse: true,
          showMultiple: false,
          openOnInit: [],
          activeClass: 'collapsed'
        });
      }
      if (document.querySelector('#footer-4')) {

        let Acc4 = new Accordion('#footer-4', {
          duration: 300,
          ariaEnabled: true,
          collapse: true,
          showMultiple: false,
          openOnInit: [],
          activeClass: 'collapsed'
        });
      }

    }
  }
};