import {v4 as uuidv4} from 'uuid';
const COOKIE_NAME = window.currenttheme + '-uid';

// Helper functie om een cookie te zetten
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Helper functie om een cookie te lezen
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Functie om de unieke gebruikers-ID te initialiseren of op te halen
function initializeUniqueId() {
  let userId = getCookie(COOKIE_NAME);
  if (!userId) {
    userId = 'uid-' + uuidv4()
    setCookie(COOKIE_NAME, userId, 365);
  }
  return userId;
}

// Wrapper functie om de unieke gebruikers-ID op te halen
export function getUniqueUserID() {
  return initializeUniqueId();
}

getUniqueUserID();

window.getUniqueUserID = getUniqueUserID;