import LazyLoad from "vanilla-lazyload";

const images = document.querySelectorAll(".lazy-bg, .lazy, img, iframe, webp, picture, video");

const createLazyLoadInstance = () => {
	return new LazyLoad({
    unobserve_entered: true,
    webp: true,
  }, 
  images
  );
};

const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);